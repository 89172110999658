import React, { useState } from "react"
import toast from "react-hot-toast"
import "react-phone-number-input/style.css"
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"
import { navigate } from "gatsby"

const InterestForm = () => {
  const [phoneValue, setPhoneValue] = useState("")
  const [formData, setFormData] = useState({
    first: "",
    last: "",
    email: "",
    // contact: "",
    // zip: "",
    // how: "",
    beds: "",
    budget: "",
    withTeamMember: "",
    // excitedAbout: "",
  })
  const [formErrors, setFormErrors] = useState({})

  // Utility function for email validation
  const validateEmail = email => /\S+@\S+\.\S+/.test(email)

  // Utility function for field validation
  const validateField = (name, value) => {
    switch (name) {
      case "first":
      case "last":
        return value.trim() ? "" : "is required"
      case "email":
        return !value.trim()
          ? "is required"
          : validateEmail(value)
          ? ""
          : "is incorrect"
      case "phone":
        // Check if there is a country code without additional digits
        const isOnlyCountryCode =
          value &&
          value.match(/^\+\d+$/) &&
          value.replace(/\D/g, "").length <= 1
        // Check if the phone number is possible if there is more than just a country code
        const isPhoneNumberPossible =
          value && !isOnlyCountryCode && isPossiblePhoneNumber(value)
        return !value || isOnlyCountryCode
          ? "is required"
          : isPhoneNumberPossible
          ? ""
          : "is incorrect"
      default:
        return ""
    }
  }

  // Utility function to main form validation
  const validateForm = () => {
    const errors = {}
    Object.keys(formData).forEach(key => {
      if (key === "phone") {
        errors[key] = validateField(key, phoneValue)
      } else {
        errors[key] = validateField(key, formData[key])
      }
    })
    setFormErrors(errors)
    return Object.keys(errors).every(key => !errors[key]) // Form is valid if there are no error messages
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })

    // Validate the current field and update the form errors state
    const fieldError = validateField(name, value)
    setFormErrors({ ...formErrors, [name]: fieldError })
  }
  const handlePhoneChange = value => {
    setPhoneValue(value)
    // Validate the phone field and update the form errors state
    const phoneError = validateField("phone", value)
    setFormErrors({ ...formErrors, phone: phoneError })
  }

  // Data for select inputs
  const hearAboutOptions = [
    "Social Media",
    "Good Days Realty Group",
    "Google",
    "Instagram",
    "Facebook",
    "Newspaper/Media",
    "Friend or Family",
    "Signage",
    "Postcard",
    "Realtor",
    "Internet Article",
    "Other",
  ]
  const budgetOptions = [
    {
      label: "$250k to $500k",
      value: "Less than $500K",
    },
    {
      label: "$500k to $800k",
      value: "$800K",
    },
    {
      label: "$800k to $1M",
      value: "$1M",
    },
    {
      label: "$1M to $2M",
      value: "Greater than $1M",
    },
    {
      label: "$2M to $3M",
      value: "$2M to $3M",
    },
    {
      label: "Greater than $3M",
      value: "Greater than $3M",
    },
  ]
  const yesNoOptions = ["Yes", "No"]
  const contactOptions = ["Email or Phone", "Email", "Phone"]
  const bedroomOptions = ["1", "2", "3", "4", "5", "6"]

  const handleSubmit = async e => {
    e.preventDefault()

    // Validate all fields before submission
    const newErrors = {}
    Object.keys(formData).forEach(key => {
      const valueToValidate = key === "phone" ? phoneValue : formData[key]
      newErrors[key] = validateField(key, valueToValidate)
    })

    // Special handling for phone field upon submission
    if (phoneValue) {
      const digitsOnly = phoneValue.replace(/\D/g, "")
      const isJustCountryCode = digitsOnly.length <= 3 // Adjust length as needed
      if (isJustCountryCode) {
        newErrors.phone = "is required"
      }
    } else {
      newErrors.phone = "is required"
    }

    setFormErrors(newErrors)

    const hasErrors = Object.values(newErrors).some(error => error)
    if (hasErrors) {
      toast.error("Please fill required fields")
      return
    }

    // If there's no error, proceed with form submission logic...
    // Include phoneValue in formData for submission
    const completeFormData = {
      ...formData,
      phone: phoneValue,
    }

    toast.loading("loading...")

    try {
      const res = await fetch(`${process.env.GATSBY_API_URL}/register-interest`, {
        method: "POST",
        body: JSON.stringify(completeFormData),
      })
      const data = await res.json()
      toast.dismiss()

      if (data.error) {
        if (data.message == "You are already on the interest list!") {
          toast("You're already in the interest list")
        } else {
          toast.error(data.message)
        }
      } else {
        toast.success("Successfully submitted!")
        // Reset form and phone input after successful submission
        setFormData({
          first: "",
          last: "",
          email: "",
          contact: "",
          zip: "",
          how: "",
          beds: "",
          budget: "",
          withTeamMember: "",
          excitedAbout: "",
        })
        setPhoneValue("")
        setFormErrors({})
        // Redirect to success page
        navigate("/form-success")
      }
    } catch (error) {
      toast.dismiss()
      toast.error("Failed to submit!")
    }
  }

  // Function to dynamically change class based on input value
  const inputClass = value =>
    value ? "border-zinc-600 text-zinc-500" : "border-zinc-600 text-white"

  return (
    <form className="grid lg:grid-cols-2 gap-4" onSubmit={handleSubmit}>
      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.first,
          formErrors.first
        )}`}
      >
        <label htmlFor="first">
          First name
          {formErrors.first && (
            <span className="text-red-500"> {formErrors.first}</span>
          )}
        </label>
        <input
          type="text"
          name="first"
          placeholder="Enter your first name"
          value={formData.first}
          onChange={handleChange}
          className="block w-full h-[25px] bg-transparent outline-none text-white mt-2"
        />
      </div>

      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.last,
          formErrors.last
        )}`}
      >
        <label htmlFor="last">
          Last name
          {formErrors.last && (
            <span className="text-red-500"> {formErrors.last}</span>
          )}
        </label>
        <input
          type="text"
          name="last"
          placeholder="Enter your last name"
          value={formData.last}
          onChange={handleChange}
          className="block w-full h-[25px] bg-transparent outline-none text-white mt-2"
        />
      </div>

      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.email,
          formErrors.email
        )}`}
      >
        <label htmlFor="email">
          Email
          {formErrors.email && (
            <span className="text-red-500"> {formErrors.email}</span>
          )}
        </label>
        <input
          type="email"
          name="email"
          placeholder="name@example.com"
          value={formData.email}
          onChange={handleChange}
          className="block w-full h-[25px] bg-transparent outline-none text-white mt-2"
        />
      </div>

      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          "phone"
        )}`}
      >
        <label htmlFor="phone">
          Phone number
          {formErrors.phone && (
            <span className="text-red-500"> {formErrors.phone}</span>
          )}
        </label>
        <PhoneInput
          international
          withCountryCallingCode
          defaultCountry="US"
          name="phone"
          value={phoneValue}
          onChange={handlePhoneChange}
          className="block w-full h-[25px] bg-transparent outline-none text-white mt-2"
          placeholder="Enter your phone number"
        />
      </div>
      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.beds
        )}`}
      >
        <label htmlFor="beds" className={{ inputClass }}>
          Bedroom preference
        </label>
        <select
          name="beds"
          value={formData.beds}
          onChange={handleChange}
          className="block w-full h-[35px] bg-transparent outline-none text-white mt-2"
        >
          <option value="" disabled selected>
            Select
          </option>
          {bedroomOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.budget
        )}`}
      >
        <label htmlFor="budget" className={{ inputClass }}>
          Budget
        </label>
        <select
          name="budget"
          value={formData.budget}
          onChange={handleChange}
          className="block w-full h-[35px] bg-transparent outline-none text-white mt-2"
        >
          <option value="" disabled selected>
            Select
          </option>
          {budgetOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div
        className={`inline-block w-full py-2 px-3 border ${inputClass(
          formData.withTeamMember
        )}`}
      >
        <label htmlFor="withTeamMember" className={{ inputClass }}>
          Name of the agent you're working with
        </label>
        <input
          type="text"
          name="withTeamMember"
          placeholder="Skip this field if not working with agent"
          value={formData.withTeamMember}
          onChange={handleChange}
          className="block w-full h-[25px] bg-transparent outline-none text-white mt-2"
        />
      </div>

      <div className="w-full">
        <button
          type="submit"
          className="w-full h-full px-10 py-4 bg-white text-lg font-semibold hover:bg-zinc-100"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default InterestForm
