import React, { useEffect } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import gsap, { Expo } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Helmet } from "react-helmet"
import { Toaster } from "react-hot-toast"
import { Link } from "gatsby"
import InterestForm from "../components/InterestForm"
import { Widget } from "@typeform/embed-react"

import styles from "../styles/styleElements"

import HorizontalScrollCards from "../components/HorizontalScrollCards"

// import heroBackground from "../images/animation-home-exterior.mp4"

const IndexPage = () => {
  const breakpoints = useBreakpoint()

  const handleClick = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
  }
  //GSAP
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".rotation-container",
          start: "top 80%",
          end: "+=600",
          scrub: true,
        },
      })
      .from(".rotation-container", {
        transformOrigin: "top center",
        scale: 0.9,
      })
      .to(".rotation-container", {
        transformOrigin: "top center",
        scale: 1,
        ease: Expo.easeOut,
      })

    // ------------- before and after animation ---------------------------------- //

    gsap.utils.toArray(".comparisonSection").forEach(section => {
      let tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: ".comparisonSection",
          start: ".comparisonSection",
          // makes the height of the scrolling (while pinning) match the width, thus the speed remains constant (vertical/horizontal)
          end: () => "+=" + section.offsetWidth,
          scrub: true,
          pin: true,
          anticipatePin: 0.2,
          // markers: true
        },
        defaults: { ease: "ease" },
      })
      // animate the container one way...
      tl1
        .fromTo(
          section.querySelector(".afterImage"),
          { xPercent: 100, x: 0 },
          { xPercent: 0 }
        )
        // ...and the image the opposite way (at the same time)
        .fromTo(
          section.querySelector(".afterImage .img"),
          { xPercent: -100, x: 0 },
          { xPercent: 0 },
          0
        )
    })
  }, [])

  return (
    <div className="relative" style={{ overflowX: "hidden" }}>
      <div className="absolute top-0 w-full flex justify-end py-5 px-6 md:px-12 z-10">
        <Link
          to="/careers"
          className="text-white text-sm md:text-md font-light hover:opacity-50"
        >
          Careers
        </Link>
      </div>

      <section className="relative h-screen">
        <StaticImage
          src="../images/6bd-exterior.png"
          className="absolute left-0 top-0 right-0 h-full z-0"
          objectFit="cover"
          alt="exterior before"
        ></StaticImage>
        <div className="absolute inset-0 bg-gradient-to-b from-sky-600 via-transparent to-transparent opacity-45" />
        <div className="absolute inset-0 flex flex-col justify-start pt-14 items-center">
          <span className="flex justify-center items-center flex-col pb-8 ">
            <svg
              width="28"
              height="29"
              viewBox="0 0 31 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1742 20.6391L6.45331 24.7206L4.92173 28.1766C4.53533 29.0433 3.90711 29.7791 3.11307 30.2949C2.31902 30.8107 1.39315 31.0845 0.447428 31.0831H0L14.0183 0H17.0402L31 31.0727H30.556C29.624 31.0722 28.7119 30.8016 27.9292 30.2934C27.1465 29.7852 26.5266 29.061 26.1437 28.2077L24.5914 24.7206L22.8258 20.6391L22.7707 20.5147L20.9879 16.4885L15.4948 4.11953L10.0121 16.4885L8.22582 20.5147"
                fill="white"
              />
              <path
                d="M19.3877 31.2081H19.3671H19.3395L19.016 31.1044C17.8707 30.7764 16.686 30.6077 15.4951 30.6033C14.1866 30.6045 12.8861 30.8097 11.6403 31.2115H11.6162L15.5123 26.6738L19.3877 31.2081Z"
                fill="white"
              />
            </svg>
            <span className="flex justify-center items-center text-white text-lg md:text-lg lg:text-xl font-extralight tracking-extrawide pt-4">
              SANCTUARY
            </span>
          </span>
          <h1 className="text-xl md:text-2xl lg:text-3xl font-light text-white text-center mb-4 stroke-black">
            {/* <span className="block font-light">Uniting <span className="font-light">Sovereign</span> Humans</span> */}
            <span className="block font-light">
              Discover a vibrant community
            </span>
          </h1>
          <h2 className=" text-base md:text-lg lg:text-xl font-light text-white text-center mb-12 max-w-sm md:max-w-sm lg:max-w-md opacity-75">
            Full service eco-luxury sovereign living. More than just a place to
            call home, it's a way of life.
          </h2>
          {/* <a href="https://edgewiserealty.com/registration/516" target="_blank" className="cursor-pointer flex px-14 py-4 text-white scale-110 rounded-full" style={{ backgroundColor: '#d2c5a2' }}>
            Reserve your lot
          </a> */}
          {/* <a href="https://edgewiserealty.com/reservation/sanctuary" target="_blank" className="cursor-pointer flex px-14 py-4 text-white scale-110 rounded-full" style={{ backgroundColor: '#d2c5a2' }}>
            Reserve your lot
          </a> */}

          {/* <a href="https://edgewiserealty.com/registration/516" target="_blank" className="cursor-pointer flex px-14 py-4 text-white scale-110 rounded-full bg-black" style={{ backgroundColor: '#15181C'}}>
            Join interest list
          </a> */}
          <button
            onClick={handleClick}
            className="cursor-pointer flex px-14 py-4 text-white scale-110 rounded-full"
            style={{ backgroundColor: "#15181C" }}
          >
            Join interest list
          </button>
        </div>
      </section>

      {/* <section className="p-6 flex items-center justify-center flex-col" style={{ backgroundColor: '#7d8455' }}>
        <p className={`${styles.body} text-center text-white opacity-75`}>
          <span className="font-medium">Sanctuary Dripping Springs</span> is a serene 104 acre community nestled in the heart of Texas Hill Country, offering a unique blend of luxury and natural beauty.
        </p>
      </section> */}

      {/** 
      <section className="relative h-160">
        {
          !breakpoints.sm ?
            <StaticImage src="../images/landing/full-map-wide.png"
              className="absolute left-0 top-0 right-0 h-full z-0" objectFit="cover" objectPosition="60% 40%"
              alt="map"></StaticImage>
            :
            <StaticImage src="../images/landing/full-map-mobile.png"
              className="absolute left-0 top-0 right-0 h-full z-0" objectFit="cover"
              alt="map"></StaticImage>
        }
      </section>
    */}

      <section className="comparisonSection w-full h-screen relative">
        <div className="comparisonImage beforeImage w-full h-full">
          <div className="w-full h-full bg-cover bg-center absolute top-0">
            <div className="absolute left-0 top-0 right-0 h-full z-0">
              {!breakpoints.sm ? (
                <StaticImage
                  src="../images/pool-day.png"
                  className="absolute left-0 top-0 right-0 h-full z-0"
                  objectFit="cover"
                  alt="exterior before"
                ></StaticImage>
              ) : (
                <StaticImage
                  src="../images/pool-day.png"
                  className="absolute left-0 top-0 right-0 h-full z-0"
                  objectFit="cover"
                  alt="exterior before"
                ></StaticImage>
              )}
            </div>
            <div className="hidden relative px-8 md:px-24 md:pl-24 lg:pl-44 pt-8 md:flex justify-between text-black">
              <div className="lg:w-1/4 w-1/3 relative pt-16">
                <h4 className={`${styles.smallHeading}`}>One with nature</h4>
                <p className={`${styles.body} mt-4`}>
                  Discover the ultimate in luxury living at Sanctuary Dripping
                  Springs
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="comparisonImage afterImage w-full h-full absolute top-0 overflow-hidden transform translate-x-full">
          <div className="w-full h-full bg-cover bg-center absolute top-0 transform -translate-x-full img">
            <div className="absolute left-0 top-0 right-0 h-full z-0">
              {!breakpoints.sm ? (
                <StaticImage
                  src="../images/pool-night.png"
                  className="absolute left-0 top-0 right-0 h-full z-0"
                  objectFit="cover"
                  alt="exterior after"
                ></StaticImage>
              ) : (
                <StaticImage
                  src="../images/pool-night.png"
                  className="absolute left-0 top-0 right-0 h-full z-0"
                  objectFit="cover"
                  alt="exterior after"
                ></StaticImage>
              )}
            </div>
            <div className="hidden z-10 relative px-8 md:px-24 md:pl-24 lg:pl-44 pt-8 md:flex justify-between">
              <div className="lg:w-1/4 w-1/3 relative pt-16">
                <h4 className={`${styles.smallHeading} text-white`}>
                  One with nature
                </h4>
                <p className={`${styles.body} mt-4 text-white`}>
                  Discover the ultimate in luxury living at Sanctuary Dripping
                  Springs
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="relative">
      Form
      </section> */}

      {/* <section className="relative h-screen">
        <div className="flex items-center justify-center h-full text-4xl">
          Gallery
        </div>

      </section> */}

      {/* <section className="relative h-screen">
         <div className="flex items-center justify-center h-full text-3xl font-light uppercase">Homes offered</div>
      </section>

      <section className="relative h-screen bg-black text-white">
         <div className="flex items-center justify-center h-full text-3xl font-light uppercase">customization</div>
      </section>

      <section className="relative h-screen">
         <div className="flex items-center justify-center h-full text-3xl font-light uppercase">Amenities, Events, Smart tech</div>
      </section>

      <section className="relative h-screen bg-black text-white">
         <div className="flex items-center justify-center h-full text-3xl font-light uppercase">Contact & footer</div>
      </section> */}

      {/* <Helmet>
        <script src="https://libs.edgewise.cloud/components@1" />
      </Helmet>
      <section
        className="relative flex"
        style={{ backgroundColor: "#15181C" }}
        id="contact"
      >
        <div className="relative w-full px-4 sm:px-2 lg:px-6 py-8">
          <div className="contact-us">
            <ewc-project-contact-form
              project-id="516"
              debug
              columns="2"
              submit-button-label="Join interest list"
            ></ewc-project-contact-form>
          </div>
        </div>
      </section> */}

      <section className='flex flex-col min-h-screen' id="contact">
        <div className="flex flex-col w-full relative p-8 pt-12 lg:pt-36 lg:px-52 text-white">
          <h4 className={`${styles.smallHeading}`}>Join interest list</h4>
          <p className={`${styles.body} mt-4 opacity-50`}>
          We're thrilled you're considering making Sanctuary Dripping Springs your new home. <br/>To better understand your needs and preferences, please take a moment to fill out the form
          </p>
        </div>
        <div className="px-8 lg:px-52 py-8 lg:pb-24">
        <InterestForm />
        </div>
        
      </section>

      {/* <section className="relative flex items-center">
        <a 
          href="https://edgewiserealty.com/reservation/sanctuary" 
          target="_blank" 
          className="cursor-pointer flex items-center justify-center py-6 text-white text-center w-full text-lg font-medium hover:bg-black" 
          style={{ backgroundColor: '#d2c5a2' }}>
            <span>Reserve your lot</span>
          </a>
      </section> */}
      {/* <section className="relative flex items-center">
        <a 
          href="https://edgewiserealty.com/registration/516" 
          target="_blank" 
          className="cursor-pointer flex items-center justify-center py-6 text-white text-center w-full text-lg font-medium hover:bg-black" 
          style={{ backgroundColor: '#15181C' }}>
            <span>Join interest list</span>
          </a>
      </section> */}

      {/* <section className="relative">
        <HorizontalScrollCards />
      </section>
      <section className="relative h-screen" id="contact">
        <Widget
          id='btlEOZhH'
          style={{ width: '100%', height: '100%' }}
          medium="landing page"
          hidden={{ hubspot_utk: '', hubspot_page_name: '', hubspot_page_url: '' }}
          transitiveSearchParams={[]}
          iframeProps={{ title: 'Join the priority list' }}
        />
      </section> */}
      <Toaster />
    </div>
  )
}

export default IndexPage
